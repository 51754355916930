@font-face {
  font-family: "brandon grotesque";
  src: url("./fonts/Brandon_reg.otf") format("opentype")
}

@font-face {
  font-family: "brandon grotesque black";
  src: url("./fonts/Brandon_blk.otf") format("opentype")
}

/* general */
* {
  font-family: "brandon grotesque";
  margin: 0;
  padding: 0;
  color: #555353;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

ul {
  list-style: none;
  padding: 0;
}

.text-logo-img {
  margin-top: -10rem;
  max-width: 100%;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #fff;
}

.loader__circle {
  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;
  background-color: #000;
}

/* navbar */
.navbar-section {
  position: absolute;
  top: 0;
  left: 2rem;
  width: 100%;
  /* transform: translate(-50%, 0%); */
}

.navbar-container {
  display: flex;
  /* justify-content: left; */
  align-items: center;
  padding: 2rem 0;
}

.default-nav ul,
.social-nav ul {
  display: none;
}

.default-nav-links {
  font-size: 1.9rem;
  text-decoration: none;
}

li:not(:last-child) .default-nav-links {
  margin-right: 2.2rem;
}

li:not(:first-child) .social-nav-links {
  margin-left: 2.2rem;
}

.social-nav li img {
  max-width: 2rem;
}

.brand-logo-img {
  height: 8rem;
}

.hero-container-banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero-container-banner video {
  position: absolute;
  top: 25%;
  scale: 6;
  opacity: 0.8;
}

.curtain-margin {
  margin-top: 14.2rem;
}

.brand-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  text-align: center;
  font-family: 'Urbanist', sans-serif;
  text-transform: uppercase;
  color: black;
}

.brand-name-main {
  font-size: 2rem;
  letter-spacing: 1.5rem;
  color: inherit;
}

.brand-name-traits {
  font-size: .9rem;
  letter-spacing: 0.3rem;
  color: inherit;
}

.active {
  font-family: "brandon grotesque black";
}

.hamburger-menu-img {
  max-width: 5.2rem;
  margin-left: auto;
  margin-right: 3.8rem;
  cursor: pointer;
}

.mobile-nav {
  position: fixed;
  padding: 4rem;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: #555353;
  z-index: 2;
}

.mobile-nav-links {
  display: inline-block;
  font-size: 1.9rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.mobile-nav-cancel-img {
  max-width: 5.2rem;
  margin-bottom: 2.5rem;
  padding: 0;
  cursor: pointer;
}

.mobile-social-nav ul {
  margin-top: 3rem;
  display: flex;
}

.mobile-social-nav li img {
  margin-right: 2rem;
  max-width: 2.5rem;
}

.banner-img {
  display: block;
  line-height: 0;
  max-width: 100%;
}

/* work content */
.work-container {
  padding: 0 2rem;
  margin-bottom: 0;
}

.work-tabs {
  font-size: 1.9rem;
  padding: 3.2rem;
}

.work-tabs ul {
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.work-tabs.work_tabs_no-padding {
  padding-bottom: 1.5rem;
}

/* series and comics heading */
.individual-series-title,
.individual-comics-title,
.episode-series-title {
  font-family: "brandon grotesque black";
  font-size: 3.5rem;
  text-align: center;
  padding-bottom: 2rem;
}

/* series and comics project layout */
.series-synopsis,
.comics-synopsis {
  font-size: 1.5rem;
}

.episodes-heading,
.extras-heading,
.vignettes-heading {
  font-family: "brandon grotesque black";
  font-size: 2rem;
  padding: 1.5rem 0;
}

.series-process {
  margin-bottom: 0.8rem;
}

/* gallery */
.layout-gallery {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}

.gallery-tile-img {
  width: 100%;
}

.gallery-tile {
  position: relative;
  line-height: 0;
}

.tile-overlay-container {
  position: absolute;
  transition: all 0.25s ease-out;
  background: #fff;
  border: solid;
  border-width: 0;
}

.title-heading {
  font-family: "brandon grotesque black";
  font-size: 2.7rem;
  position: relative;
  text-align: center;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.click-to-see {
  font-size: 1.8rem;
  position: relative;
  text-align: center;
  opacity: 0;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tile-title {
  margin-top: 1.5rem;
  text-align: center;
}

.overlay-from-bottom {
  width: 100%;
  height: 0%;
  bottom: 0;
  left: 0;
}

.gallery-tile:hover .tile-overlay-container {
  border-width: thin;
}

.gallery-tile:hover .title-heading {
  opacity: 1;
}

.gallery-tile:hover .overlay-from-bottom {
  height: 100%;
}

.clickable-tile,
.clickable-tile .tile-overlay-container {
  cursor: pointer;
}

.clickable-title {
  cursor: pointer;
  display: inline-block;
}

.bordered-tiles {
  border: solid;
  border-width: thin;
}

/* episode-video */
.episode-video {
  position: relative;
  padding-bottom: 56.25%;
  line-height: 0;
  border: solid;
  border-width: thin;
}

.episode-video-frame {
  position: absolute;
  width: 100%;
}

.playing-info {
  font-size: 1.5rem;
  padding: 1rem 0 2rem 0;
}

.button {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.8rem;

}

.playing-info img {
  width: 100%;
  height: 100%;
}

.playing-info-message {
  font-family: "brandon grotesque black";
}

.episode-video-title {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.play-button {
  display: flex;
  justify-content: center;
}

.play-button img {
  width: 80%;
}

/* footer */
.footer-section {
  background: #ededed;
}

.footer-container {
  margin-top: 5rem;
  padding: 5rem 3.2rem;
}

.footer-container .social-nav ul {
  display: flex;
  justify-content: center;
}

.footer-container .social-nav-links {
  margin: 0 1rem;
}

.copyright {
  font-size: 2rem;
  margin-top: 2rem;
  text-align: center;
}

/* About Page ---------------- */
.about-container {
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 1rem 0 5rem 0;
}

.about-img {
  line-height: 0;
}

.about-img img {
  height: 75vh;
  width: 100%;
  border: solid;
  border-width: thin;
  object-fit: cover;
}

.its-me-heading {
  font-family: "brandon grotesque black";
  color: #fff;
  text-align: center;
  font-size: 3rem;
  background: #37383b;
}

.about-content {
  border: solid;
  border-width: thin;
  font-size: 1.6rem;
  background: #f4f4f4;
}

.about-content p {
  padding: 2rem;
}

.speciality-tools {
  display: grid;
  grid-template-columns: 40% 1fr;
  margin-top: 2.5rem;
  font-size: 1.4rem;
  /* font-weight: 900; */
}

.speciality-heading,
.tools-heading {
  margin-bottom: 1rem;
  font-weight: 900;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.tools {
  display: flex;
  flex-wrap: wrap;
  gap: .2rem;
}

.tools li {
  padding: .3rem .8rem;
  background-color: #37383b;
  color: #fff;
}


/* Contact Page ---------------- */
.contact-container {
  padding: 0 2rem;
  margin-bottom: 5rem;
}

.lets-get-creative-heading {
  font-size: 4rem;
  padding: 1rem 0 3.5rem 0;
  text-align: center;
}

.client-message {
  display: block;
  margin: 0 0 1.8rem 0;
  font-size: 2rem;
  text-align: center;
}

.email-address {
  font-family: "brandon grotesque black";
  font-size: 2.5rem;
  text-align: center;
  padding: 2rem 0;
  background-color: #37383B;
  color: #fff;
}

.humour-message {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 1.8rem;
}

label {
  display: block;
  margin: 0 0 1.2rem 0;
  font-size: 1.8rem;
}

.name-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

input[type="text"],
textarea {
  width: 100%;
  font-size: 2rem;
  padding: 0.2rem 1.5rem;
  margin-top: 0.4rem;
  border: solid;
  border-width: thin;
  background: #f4f4f4;
  resize: none;
}

.message-box {
  height: 16rem;
}

.info-source-box {
  height: 12rem;
}

input[type="submit"] {
  font-size: 1.5rem;
  padding: 0.6rem 1.5rem;
  margin-top: 1rem;
  text-align: center;
  border: solid;
  border-width: thin;
  cursor: pointer;
  background: #fff;
  transition: all 0.2s ease-out;
}

input[type="submit"]:hover {
  background: #37383b;
  color: #fff;
}

.name-lower-labels {
  font-size: 1rem;
  margin-top: 0.2rem;
}

/* cursor type handling */
.unclickable-tile .title-heading,
.individual-series-title,
.individual-comics-title {
  cursor: default;
}

/* Scrollbar------------------------ */
::-webkit-scrollbar {
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 800px) {

  /* navbar */
  .navbar-section {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .navbar-container {
    justify-content: center;
  }

  .default-nav ul,
  .social-nav ul {
    display: flex;
  }

  .brand-logo {
    margin: 0 15rem;
  }

  .brand-logo-img {
    height: 10rem;
  }

  .hero-container-banner {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .hero-container-banner video {
    position: absolute;
    top: -20%;
    scale: 3;
    opacity: 0.8;
  }

  .curtain-margin {
    margin-top: 14.2rem;
  }

  .brand-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    text-transform: uppercase;
    color: black;
  }

  .brand-name-main {
    font-size: 3.52rem;
    letter-spacing: 2rem;
    color: inherit;
  }

  .brand-name-traits {
    font-size: 1.2rem;
    letter-spacing: 0.3rem;
    color: inherit;
  }


  .hamburger-menu-img {
    display: none;
  }

  .mobile-nav {
    display: none;
  }

  /* work content */
  .work-container {
    padding: 0 3.2rem;
  }

  /* series and comics heading */
  .individual-series-title,
  .individual-comics-title,
  .episode-series-title {
    font-size: 5.1rem;
  }

  /* series and comics project layout */
  .series-synopsis,
  .comics-synopsis {
    font-size: 2rem;
  }

  .episodes-heading,
  .extras-heading,
  .vignettes-heading {
    text-align: right;
    font-size: 2.7rem;
    padding: 3rem 0;
  }

  .series-process {
    margin-bottom: 1.5rem;
  }

  /* gallery */
  .layout-gallery {
    gap: 2.2rem;
  }

  .title-heading {
    top: 45%;
    left: 50%;
  }

  .tile-title {
    display: none;
  }

  .gallery-tile:hover .click-to-see {
    opacity: 1;
  }

  .episode-video-container {
    text-align: left;
    margin-bottom: 3rem;
  }

  .playing-info {
    font-size: 1.9rem;
    display: flex;
    align-items: center;
    padding: 1.5rem 0 0 0;
  }

  .button {
    width: 2rem;
    height: 2rem;
  }

  .episode-video-title {
    font-size: 1.9rem;
  }

  /* footer */
  .footer-container {
    margin-top: 7.5rem;
    padding: 5rem 24rem;
  }

  .footer-container .social-nav-links {
    margin: 0 2rem;
  }

  /* About Page ---------------- */
  .about-container {
    padding: 0 3.2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3.2rem;
    margin: 3rem 0 10rem 0;
  }

  .about-img img {
    object-position: center 20%;
  }

  .its-me-heading {
    font-size: 7.5rem;
  }

  .about-content {
    font-size: 2.2rem;
  }

  .about-content p {
    padding: 4rem;
  }

  .speciality-tools {
    display: grid;
    grid-template-columns: 40% 1fr;
    margin-top: 2.5rem;
    font-size: 1.8rem;
    /* font-weight: 900; */
  }

  .speciality-heading,
  .tools-heading {
    margin-bottom: 1rem;
    font-weight: 900;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .tools {
    display: flex;
    flex-wrap: wrap;
    gap: .2rem;
  }

  .tools li {
    padding: .3rem .8rem;
    background-color: #37383b;
    color: #fff;
  }

  /* Contact Page ---------------- */
  .contact-container {
    padding: 0 12rem;
    margin-bottom: 7.5rem;
  }

  .lets-get-creative-heading {
    font-size: 6rem;
    padding: 4.5rem 0 6.5rem 0;
  }

  .client-message {
    font-size: 2.4rem;
  }

  .email-address {
    font-size: 4rem;
  }

  label {
    margin: 0 0 1.8rem 0;
    font-size: 2.4rem;
  }

  input[type="text"],
  textarea {
    padding: 0.8rem 1.5rem;
    margin-top: 0.8rem;
  }

  input[type="submit"] {
    font-size: 2rem;
    padding: 0.8rem 3rem;
  }

  .name-lower-labels {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1050px) {

  /* navbar */
  .navbar-section {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .brand-logo {
    margin: 0 39rem;
  }

  .brand-logo-img {
    /* animation-delay: 5s;
    max-width: 20.5rem; */
    height: 12rem;
    border: thin black;
  }

  .hero-container-banner {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .hero-container-banner video {
    position: absolute;
    top: -50%;
    scale: 2.6;
    opacity: 0.8;
  }

  .curtain-margin {
    margin-top: 16.2rem;
  }

  .brand-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    text-transform: uppercase;
    color: black;
  }

  .brand-name-main {
    font-size: 5.12rem;
    letter-spacing: 2rem;
    color: inherit;
  }

  .brand-name-traits {
    font-size: 1.6rem;
    letter-spacing: 0.3rem;
    color: inherit;
  }

  /* work content */
  .work-container {
    padding: 0 12.7rem;
  }

  .work-tabs-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
  }

  .work-tabs {
    font-size: 1.9rem;
    padding: 3.2rem 0;
  }

  .work-tabs ul {
    justify-content: left;
  }

  .decorations {
    text-decoration: none;
  }

  /* series and comics heading */
  .individual-series-title,
  .individual-comics-title,
  .episode-series-title {
    text-align: left;
  }

  /* series and comics project layout */
  .series-synopsis,
  .comics-synopsis {
    font-size: 2.2rem;
  }

  /* gallery */
  .title-heading {
    top: 46%;
    left: 50%;
  }

  .click-to-see {
    top: 54%;
    left: 50%;
  }

  .gallery-tile:hover .click-to-see {
    opacity: 1;
  }

  .episode-video-container {
    text-align: left;
    margin-bottom: 3rem;
  }

  .case-study-urls {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    font-size: 1.2rem;
  }

  .case-study-urls li {
    list-style-type: none;
  }

  .case-study-urls li a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    background-color: #000;
    color: #fff;
    padding: 1rem 1.5rem;
  }

  /* footer */
  .footer-img {
    display: block;
    line-height: 0;
    max-width: 100%;
  }

  /* About Page ---------------- */
  .about-container {
    padding: 0 12.8rem;
    grid-template-columns: 35% 1fr;
    gap: 2rem;
    margin: 4rem 0 10rem 0;
  }

  .speciality-tools {
    display: grid;
    grid-template-columns: 40% 1fr;
    margin-top: 2.5rem;
    font-size: 1.8rem;
    /* font-weight: 900; */
  }

  .speciality-heading,
  .tools-heading {
    margin-bottom: 1rem;
    font-weight: 900;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .tools {
    display: flex;
    flex-wrap: wrap;
    gap: .2rem;
  }

  .tools li {
    padding: .3rem .8rem;
    background-color: #37383b;
    color: #fff;
  }

  .about-img img {
    object-position: center 20%;
  }

  /* Contact Page ---------------- */
  .contact-container {
    padding: 0 24rem;
  }

  .lets-get-creative-heading {
    font-size: 7.6rem;
  }

  .email-address {
    font-size: 5.1rem;
    padding: 3rem 0;
  }
}